import React from "react"
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"

import albar from "../../images/Seal_of_the_Unified_Judicial_System_of_Alabama.svg"
import bhammag from "../../images/526905_10150910915357942_1607677693_n.jpg"
import nacba from "../../images/2020-Member-Logo.jpg"
import bhambar from "../../images/bhambar.png"

const Jumbotron = () => {
	const imageData = useStaticQuery(
		graphql`
			query {
				whiteColumns: file(relativePath: { eq: "white-columns.jpg"}) {
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				},
				sunflowers: file(relativePath: { eq: "photography-of-woman-surrounded-by-sunflowers-2.jpg" }) {
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				},
				family: file(relativePath: { eq: "family-of-four-walking-at-the-street-2253879.jpg" }) {
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				},
				# albar: file(relativePath: { eq: "Seal_of_the_Unified_Judicial_System_of_Alabama.svg" }) {
				# 	childImageSharp {
				# 		fixed(height: 130, quality: 100) {
				# 			...GatsbyImageSharpFixed_withWebp_tracedSVG
				# 		}
				# 	}
				# },
				# bhammagazine: file(relativePath: { eq: "526905_10150910915357942_1607677693_n.jpg" }) {
				# 	childImageSharp {
				# 		fixed(height: 130, quality: 100) {
				# 			...GatsbyImageSharpFixed_withWebp_tracedSVG
				# 		}
				# 	}
				# },
				# nacba: file(relativePath: { eq: "2020-Member-Logo.jpg" }) {
				# 	childImageSharp {
				# 		fixed(width: 130, height: 130, quality: 100) {
				# 			...GatsbyImageSharpFixed_withWebp_tracedSVG
				# 		}
				# 	}
				# },
				# bhambar: file(relativePath: { eq: "bhambar.png" }) {
				# 	childImageSharp {
				# 		fixed(width: 130, height: 130, quality: 100) {
				# 			...GatsbyImageSharpFixed_withWebp_tracedSVG
				# 		}
				# 	}
				# }
			}
		`
	);

	// const imageData = sunflowers.childImageSharp.fluid

	// console.log(imageData);

	return (<section>
		<Carousel>

			<Carousel.Item>
				<Img className="img-fluid" fluid={imageData.whiteColumns.childImageSharp.fluid} alt="White columns from a court house" />

				<Carousel.Caption className="text-dark">
					<h1 style={{ fontSize: '4rem', textShadow: '2px 2px 5px #94a0b0' }}>Hope is here</h1>
					<p style={{ textShadow: '2px 2px 5px #94a0b0' }}>Comprehensive Bankruptcy and Personal Injury Representation in Alabama</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<Img className="img-fluid w-100" fluid={imageData.sunflowers.childImageSharp.fluid} alt="woman surrounded by sunflowers" />

				<Carousel.Caption>
					<h3 style={{ fontSize: '4rem', textShadow: '2px 2px 5px #31581c', color: '#ffd700' }}>Are you looking for a fresh start<br /> after tirelessly trying to pay back your creditors?</h3>
					<p style={{ fontSize: '1.4rem', textShadow: '1px 1px 1px #000', color: '#fff' }}>You don't have to live under the pressure of mounting debt.</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<Img className="img-fluid w-100" fluid={imageData.family.childImageSharp.fluid} alt="family holding hands" />

				<Carousel.Caption>
					<h3 style={{ fontSize: '4rem', textShadow: '2px 2px 5px #4f1416' }}>Birmingham<br /> Personal Injury<br /> Attorneys</h3>
					<p style={{ fontSize: '1.4rem', textShadow: '1px 1px 1px #000', color: '#fff' }}>Have you or a loved been injured in a car accident<br /> or hurt on someone else's property?</p>
				</Carousel.Caption>
			</Carousel.Item>
		</Carousel>

		<div className="position-relative" style={{ top: -100 }}>
			<Container>
				<Row className="align-items-start">
					<Col className="text-center">
						<div className="bg-light shadow p-3">
							{/* <Img className="img-fluid" fluid={imageData.albar.childImageSharp.fluid} alt="Alabama Bar logo" /> */}
							<img className="img-fluid" src={albar} style={{ maxHeight: '130px' }} alt="Alabama Bar logo" />
						</div>
					</Col>
					<Col className="text-center">
						<div className="bg-white shadow p-3">
							{/* <Img className="img-fluid" fluid={imageData.bhambar.childImageSharp.fluid} style={{ maxHeight: '130px' }} alt="Birmingham Bar logo" /> */}
							<img className="img-fluid" src={bhambar} style={{ maxHeight: '130px' }} alt="Birmingham Bar logo" />
						</div>
					</Col>
					{/* <Col className="text-center bg-light">
						<div className="text-light p-5" style={{background: '#0070ba', height: '130px'}}>
							Shelby County Bar Association Member
						</div>
					</Col> */}
					<Col className="text-center">
						<div className="shadow p-3" style={{ background: '#fe2c51' }}>
						{/* <Img className="img-fluid" fluid={imageData.bhammagazine.childImageSharp.fluid} style={{ maxHeight: '130px' }} alt="Bham magazine logo" /> */}
							<img className="img-fluid" src={bhammag} style={{ maxHeight: '130px' }} alt="Bham magazine logo" />
						</div>
					</Col>
					<Col className="text-center">
						<div className="bg-light shadow p-3">
							{/* <Img className="img-fluid" fluid={imageData.nacba.childImageSharp.fluid} style={{ maxHeight: '130px' }} alt="National Association of Consumer Bankruptcy Attorneys Member logo" /> */}
							<img className="img-fluid" src={nacba} style={{ maxHeight: '130px' }} alt="National Association of Consumer Bankruptcy Attorneys Member logo" />
						</div>
					</Col>

				</Row>
			</Container>
		</div>
	</section>);
}

export default Jumbotron;