import React from "react"
import { Container, Row, Col, CardDeck, Card, Button } from 'react-bootstrap'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from "gatsby-image"

const PracticeAreas = () => {
	const imageData = useStaticQuery(
		graphql`
			query {
				bankruptcy: file(relativePath: { eq: "debt.jpg"}) {
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				},
				personalInjury: file(relativePath: { eq: "rsz_injury.jpg" }) {
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				},
				family: file(relativePath: { eq: "practice-area-card-family-law.jpg" }) {
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
			}
		`
	);

	return (<Container className="practice-areas">
		<Row className="pb-2">
			<Col className="text-center">
				<h2>Practice Areas</h2>
				<p>Our Alabama bankruptcy attorneys provide experienced representation for<br /> individuals, families and businesses faced with debt across the state.</p>
			</Col>
		</Row>

		<Row>
			<Col>
				<CardDeck>
					<Card>
						<Card.Img variant="top" as={Img} fluid={imageData.bankruptcy.childImageSharp.fluid} />
						<Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center text-light">
							<Card.Title>Bankruptcy Law</Card.Title>
							<Card.Text className="d-none">
								Have you done everything to try and pay off your debt? Are you looking for a way to stop foreclosure and keep your assets?
							</Card.Text>
							<Button variant="secondary" className="stretched-link d-none" as={Link} to="/practice-areas/bankruptcy-law">Learn More</Button>
						</Card.ImgOverlay>
					</Card>
					<Card>
						<Card.Img variant="top" as={Img} fluid={imageData.personalInjury.childImageSharp.fluid} />
						<Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center text-light">
							<Card.Title>Personal Injury Law</Card.Title>
							<Card.Text className="d-none">
								Have you or a loved been injured in a car accident or hurt on someone else's property? Have you been unable to work after being hurt on the job?
							</Card.Text>
							<Button variant="secondary" className="stretched-link d-none" as={Link} to="/practice-areas/personal-injury-law">Learn More</Button>
						</Card.ImgOverlay>
					</Card>
					<Card>
						<Card.Img variant="top" as={Img} fluid={imageData.family.childImageSharp.fluid} />
						<Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center text-light">
							<Card.Title>Family Law</Card.Title>
							<Card.Text className="d-none">
								Ready to start a family, adopt a child, write a will, file for divorce?
							</Card.Text>
							<Button variant="secondary" className="stretched-link d-none" as={Link} to="/practice-areas/family-law">Learn More</Button>
						</Card.ImgOverlay>
					</Card>
				</CardDeck>
			</Col>
		</Row>

		<Row className="mt-2">
			<Col className="text-center">
				<p><Button variant="primary" as={Link} to="/practice-areas">See All</Button></p>
			</Col>
		</Row>
	</Container>);
}

export default PracticeAreas;