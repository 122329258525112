import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faUserFriends, faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import ContactUs from "../components/home/contactUs"
import PracticeAreas from "../components/home/practice-areas"
import AboutUs from "../components/home/aboutUs"
import HowItWorks from "../components/home/howItWorks"
import Testominals from "../components/home/testominals"
import Jumbotron from "../components/home/jumbotron"
import BadThings from "../components/home/badThings"

export default () => <Layout>
	<Helmet title="Gina McDonald &amp; Associates" defer={false} />

	<Jumbotron />

	<AboutUs />

	<ContactUs />

	<PracticeAreas />

	{/* <Container>
		<hr />
	</Container> */}

	<BadThings />

	<HowItWorks />

	<section className="my-5 d-none">
		<div className="container pt-5">
			<div className="row">
				<div className="col col-md-8 offset-md-2 text-center">
					<h2 className="">HOW IT WORKS</h2>
					{/* our Birmingham bankruptcy attorneys provide experienced representation for individual and families faced with debt in Alabama. */}
					<p className="text-muted">We have helped clients for more than 20 years to build solutions in the face of credit card debts, medical bills and other types of debt through the process of Chapter 7, Chapter 11, and Chapter 13 bankruptcy filings.</p>
				</div>
			</div>
			<div className="row my-4">
				<div className="col">
					<div className="card h-100 text-center mx-5">

						<div className="card-body align-items-center">
							<h5 className="card-title"><FontAwesomeIcon className="mx-1 my-3" icon={faEdit} /></h5>
							<h5 className="card-title">Request Quote</h5>
							<p className="card-text text-muted">Send us your request</p>
						</div>
					</div>
				</div>
				<div className="col">
					<div className="card h-100 text-center mx-5">
						<div className="card-body">
							<h5 className="card-title"><FontAwesomeIcon className="mx-1 my-3" icon={faUserFriends} /></h5>
							<h5 className="card-title">Consultation</h5>
							<p className="card-text text-muted">Review your situation with us</p>
						</div>
					</div>
				</div>
				<div className="col">
					<div className="card h-100 text-center mx-5">
						<div className="card-body">
							<h5 className="card-title"><FontAwesomeIcon className="mx-1 my-3" icon={faFolderOpen} /></h5>
							<h5 className="card-title">File</h5>
							<p className="card-text text-muted">We will file your case</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>


	<Testominals />

</Layout>

// export const query = graphql`
//   query MyQuery {
//     file(relativePath: { eq: "eric-ward-akT1bnnuMMk-unsplash.jpg" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         fluid {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//   }
// `