import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Img from "gatsby-image"

const AboutUs = () => {
	const { gina } = useStaticQuery(
		graphql`
			query {
				gina: file(relativePath: { eq: "gina-mcdonald-right.jpg"}) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 200 maxHeight: 250) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
			}
		`
	);

	return (<Container className="my-3">
		<Row>
			{/* <Col>Experienced</Col>
		<Col>Compassionate</Col>
		<Col>Cost Effective</Col> */}

			<Col className="col-12">
				<div>
					<h2 className="text-center">About Us</h2>
					<p>We help residents of Alabama who have lost a job, suffered a medical tragedy, divorce, or are burdened with debt to stop foreclosure, repossession, garnishment, creditor harassment, and judgments by creating streamlined debt repayment plans.</p>
					{/* <p className="text-center">We help residents of Alabama file for workers compensation, divorce, child support, personal and business bankruptcies with locations in Oneonta, Jasper and Pelham.</p> */}
				</div>
			</Col>
		</Row>

		<Row>
			<Col><Img className="img-fluid" fluid={gina.childImageSharp.fluid} alt="Gina McDonald profile" /></Col>
			<Col md="6">

				<p>Financial uncertainty and personal injuries threaten the security of you and your family and are very stressful to deal with. It is important to seek qualified legal advice to learn about all of your options when you are struggling.</p>
				{/* <h3 className="text-capitalize">We are <span className="text-primary">compassionate</span></h3> */}
				<p className="text-justify">Your first meeting will be with an attorney qualified to help you understand your situation and walk you through every step of the process. &nbsp;We realize how difficult it can be to make the initial call to a law firm. You will always be treated with respect and dignity by our staff.</p>
				<p className="text-justify">We care about the struggles that you and your family are facing.</p>

			</Col>
			<Col md="4">
				<div className="bg-light p-4">
					<h5 className="mb-4 text-center font-weight-bold">WE ARE MAKING PROGRESS</h5>

					<p className="mb-1">Divorce</p>
					<div className="progress mb-4">
						<div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: "60%" }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
					</div>

					<p className="mb-1">Bankruptcies &amp; Debt Consolidation</p>
					<div className="progress mb-4">
						<div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
					</div>

					<p className="mb-1">Personal Injury</p>
					<div className="progress mb-4">
						<div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: "85%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
					</div>
{/* Car Accidents, Workers’ Compensation, Slip-and-Fall Accidents */}
					{/* <p className="mb-1"></p>
					<div className="progress mb-4">
						<div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: "60%" }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
					</div>

					<p className="mb-1">Slip-and-Fall Accidents</p>
					<div className="progress">
						<div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: "40%" }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
					</div> */}

				</div>
			</Col>
		</Row>
	</Container>);
}

export default AboutUs;