import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import BackgroundImage from "gatsby-background-image"

const Testominals = () => {
	const { desktop } = useStaticQuery(
		graphql`
			query {
				desktop: file(relativePath: { eq: "photography-of-woman-surrounded-by-sunflowers-2.jpg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 4160) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
			}
		`
	)

	const imageData = [`linear-gradient(0deg, rgba(123,144,149,.9) 10%, rgba(123,144,149,.9))`, desktop.childImageSharp.fluid]
	return (
		<BackgroundImage Tag="section" fluid={imageData} backgroundColor={`#040e18`}>
			<Container className="text-light py-5">
				<Row className="my-5">
					<Col><h1>What People<br /> Are saying about Us</h1></Col>
				</Row>

				<Row className="my-5">
					<Col>
						<Carousel indicators={false}>
							<Carousel.Item style={{ height: "125px" }}>
								<div className="media" style={{ margin: "0 20%" }}>
									<img className="mr-3" src="https://lh6.googleusercontent.com/-DM4luSY9nVw/AAAAAAAAAAI/AAAAAAAAAAA/Pf67bo-oM9g/w100-h100-p-rp-mo-ba3-br100/photo.jpg" alt="Cathy Morgan" />
									<div className="media-body">
										<blockquote className="">
											<p className="mb-0">Gina was highly recommended by her peers as as a bankruptcy attorney. She and her staff did a great job. I recommend her for anyone going through this process. She made it easy and bearable.</p>
											<footer className="blockquote-footer text-light">Cathy Morgan <FontAwesomeIcon icon={faStar} className="text-secondary" /><FontAwesomeIcon icon={faStar} className="text-secondary" /><FontAwesomeIcon icon={faStar} className="text-secondary" /><FontAwesomeIcon icon={faStar} className="text-secondary" /><FontAwesomeIcon icon={faStar} className="text-secondary" /></footer>
										</blockquote>
									</div>
								</div>
							</Carousel.Item>
							<Carousel.Item style={{ height: "125px" }}>
								<div className="media" style={{ margin: "0 20%" }}>
									<img className="mr-3" src="https://lh6.googleusercontent.com/-SwLdRShUx6g/AAAAAAAAAAI/AAAAAAAAAAA/G3Uu2mtQE3Q/w100-h100-p-rp-mo-br100/photo.jpg" alt="Staci Machado" />
									<div className="media-body">
										<blockquote className="">
											<p className="mb-0">Gina McDonald and her whole staff are fantastic! She took the time to listen and understand my needs. I had no experience dealing with the legal process. But, Gina and her staff helped me every step of the way. I highly recommend her. Great experience!</p>
											<footer className="blockquote-footer text-light">Staci Hayes Machado <FontAwesomeIcon icon={faStar} className="text-secondary" /><FontAwesomeIcon icon={faStar} className="text-secondary" /><FontAwesomeIcon icon={faStar} className="text-secondary" /><FontAwesomeIcon icon={faStar} className="text-secondary" /><FontAwesomeIcon icon={faStar} className="text-secondary" /></footer>
										</blockquote>
									</div>
								</div>
							</Carousel.Item>
							<Carousel.Item style={{ height: "125px" }}>
								<div className="media" style={{ margin: "0 20%" }}>
									<img className="mr-3" src="https://lh5.googleusercontent.com/-fwNjcQfGDFY/AAAAAAAAAAI/AAAAAAAAAAA/AscpRln48h4/w100-h100-p-rp-mo-br100/photo.jpg" alt="Benjamin Tubbs" />
									<div className="media-body">
										<blockquote className="">
											<p className="mb-0">Gina and her staff were very nice and caring of my situation! I highly recommend her!</p>
											<footer className="blockquote-footer text-light">Benjamin Tubbs <FontAwesomeIcon icon={faStar} className="text-secondary" /><FontAwesomeIcon icon={faStar} className="text-secondary" /><FontAwesomeIcon icon={faStar} className="text-secondary" /><FontAwesomeIcon icon={faStar} className="text-secondary" /><FontAwesomeIcon icon={faStar} className="text-secondary" /></footer>
										</blockquote>
									</div>
								</div>
							</Carousel.Item>
						</Carousel>
					</Col>
				</Row>
			</Container>
		</BackgroundImage>
	)
}

export default Testominals