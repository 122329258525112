import React from "react"
import { Container, Row, Col, CardDeck, Card, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import { faEdit, faUserFriends, faFolderOpen } from "@fortawesome/free-solid-svg-icons"

export default () => <Container className="my-5">
	<Row>
		<Col className="text-center mb-3">
			<h2>HOW IT WORKS</h2>
			<p className="text-muted">Remember that you are not alone. Our staff and attorneys have helped thousands of people across central Alabama in the last 20 years...people just like you!</p>
		</Col>
	</Row>

	<Row>
		<Col>
			<CardDeck>
				<Card className="text-center mx-5">
					<Card.Title><FontAwesomeIcon className="mx-1 my-3" icon={faEdit} style={{fontSize: '4vh'}} /></Card.Title>
					<Card.Title>Request Quote</Card.Title>
					<Card.Text className="text-muted">
						Send us your request
					</Card.Text>
					<Button variant="secondary" className="stretched-link d-none" as={Link} to="/practice-areas/bankruptcy-law">Learn More</Button>
				</Card>
				<Card className="text-center mx-5">
					<Card.Title><FontAwesomeIcon className="mx-1 my-3" icon={faUserFriends} style={{fontSize: '4vh'}} /></Card.Title>
					<Card.Title>Consultation</Card.Title>
					<Card.Text className="text-muted">
						Review your situation with us
					</Card.Text>
					<Button variant="secondary" className="stretched-link d-none" as={Link} to="/practice-areas/bankruptcy-law">Learn More</Button>
				</Card>
				<Card className="text-center mx-5">
					<Card.Title><FontAwesomeIcon className="mx-1 my-3" icon={faFolderOpen} style={{fontSize: '4vh'}} /></Card.Title>
					<Card.Title>File</Card.Title>
					<Card.Text className="text-muted">
						We will file your case
					</Card.Text>
					<Button variant="secondary" className="stretched-link d-none" as={Link} to="/practice-areas/bankruptcy-law">Learn More</Button>
				</Card>
			</CardDeck>
		</Col>
	</Row>
</Container>