import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import BackgroundImage from "gatsby-background-image"

const BadThings = () => {
	const { desktop } = useStaticQuery(
		graphql`
			query {
				desktop: file(relativePath: { eq: "eric-ward-akt1bnnummk-unsplash.jpg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 4160) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
			}
		`
	)

	const imageData = [`linear-gradient(90deg, rgba(0,0,0,1) 25%, rgba(0,0,0,0))`, desktop.childImageSharp.fluid]
	return (
		<BackgroundImage Tag="section" fluid={imageData} backgroundColor={`#2a2221`} className="bg-light my-5" style={{
			height: '25rem',

		}}>
			{/* <section
            className="bg-light my-5"
            style={{
                // backgroundImage: 'url('+whiteColumns+')',
                backgroundImage: "linear-gradient(90deg, rgba(0,0,0,1) 25%, rgba(0,0,0,0)), url(" + badThingsHappenPhoto + ")",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '25rem',
        
            }} > */}
			<Container style={{ height: '100%' }}>
				<div className="d-flex flex-column justify-content-center align-items-start text-light" style={{ height: '100%' }}>

					<h3 style={{ fontSize: '4.5vh' }}>Bad Things<br />Happen</h3>

					<h4 style={{ fontSize: '2vh' }}>We will get you through it</h4>

					<a className="btn btn-primary mt-3" href="tel://12059823325">Click to call</a>
				</div>
			</Container>
		</BackgroundImage>
	)
}

export default BadThings