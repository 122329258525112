import React from "react"
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Container, Row, Col, Button } from 'react-bootstrap'
import BackgroundImage from "gatsby-background-image"

const ContactUs = () => {
    const { desktop } = useStaticQuery(
        graphql`
			query {
				desktop: file(relativePath: { eq: "org.IMG_8024.jpeg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 4160) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
			}
		`
    )

    const imageData = [`linear-gradient(90deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,1))`,
        desktop.childImageSharp.fluid]
    return (
        <BackgroundImage Tag="section" fluid={imageData} backgroundColor={`#2a2221`} className="bg-light my-5">
            <Container>
                <Row style={{ height: '25rem' }}>
                    <Col md={{ span: 6, offset: 6 }} className="d-flex flex-column justify-content-center align-items-end text-light text-right" style={{ height: '100%' }}>
                        <h2>Schedule Your Free Consultation</h2>
                        <p>We offer free consultations on a variety of legal services relating to bankruptcy and personal injury.</p>
                        <p><Button as={Link} to="/contact" variant="primary">Submit your request</Button></p>
                    </Col>
                </Row>
            </Container>
        </BackgroundImage>
    )
}

export default ContactUs